<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <!-- Help -->
            <help
              v-if="contract != null && contract.type == 'Huur'"
              bg-color="#fff"
              help-id="1106"
            ></help>
            <help
              v-else-if="contract != null && contract.type == 'Partage'"
              bg-color="#fff"
              help-id="1107"
            ></help>
            <help
              v-else-if="contract != null && contract.type == 'Staffel'"
              bg-color="#fff"
              help-id="1108"
            ></help>
            <help
              v-else-if="contract != null && contract.type == 'Suppletie'"
              bg-color="#fff"
              help-id="1109"
            ></help>
            <help
              v-else-if="contract != null && contract.type == 'Uitkoop'"
              bg-color="#fff"
              help-id="1110"
            ></help>
          </v-col>
        </v-row>

        <v-row v-if="contract != null">
          <v-col
            cols="12"
            md="7"
          >
            <!-- Contract Details -->
            <details-contract :contract="contract"></details-contract>
            <!-- Message -->
            <message :contract="contract"></message>

            <!-- Change contract rejected contract to new concept -->
            <v-alert
              v-if="formError != ''"
              text
              color="error"
              >{{ formError }}</v-alert
            >

            <v-btn
              v-if="
                rights.edit &&
                contract.canEdit &&
                user.organisationTypeId != 2 &&
                contract.state == 'rejected' &&
                contract.isLatestVersion
              "
              rounded
              large
              elevation="0"
              @click="submitConcept"
              :loading="formLoading"
              color="secondary"
              class="text-lowercase mb-2 mr-2 align-self-start not-visible-on-print"
              >Afspraak aanpassen
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >
            <v-btn
              v-else-if="
                rights.edit &&
                contract.canEdit &&
                user.organisationTypeId != 2 &&
                contract.state == 'approved' &&
                contract.isLatestVersion
              "
              rounded
              large
              elevation="0"
              @click="submitConcept"
              :loading="formLoading"
              color="warning"
              class="text-lowercase primary--text mb-2 mr-2 align-self-start not-visible-on-print"
              >Afspraak herzien
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >

            <!-- Edit -->
            <v-btn
              v-if="rights.edit && contract.state == 'concept'"
              rounded
              large
              outlined
              elevation="0"
              color="primary"
              :to="{ name: 'ContractsEdit', params: { type: contract.type.toLowerCase(), contractId: contract.id } }"
              :loading="formLoading"
              class="text-lowercase mb-2 mr-2 align-self-start not-visible-on-print"
              >Afspraak bewerken
              <v-icon
                small
                right
                >mdi-file-edit-outline</v-icon
              ></v-btn
            >
            <!-- Voorstellen -->
            <v-btn
              v-if="rights.edit && contract.state == 'concept'"
              rounded
              large
              elevation="0"
              color="secondary"
              @click="submitProposed"
              :loading="formLoading"
              class="text-lowercase mb-2 mr-2 align-self-start not-visible-on-print"
              >Afspraak voorstellen
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >

            <!-- Comment on contract (theater) -->
            <comment
              v-if="
                (rights.edit || rights.approve) &&
                contract.canEdit &&
                user.organisationTypeId == 2 &&
                (contract.state == 'proposed' || contract.state == 'revised') &&
                contract.isLatestVersion
              "
              :contract="contract"
              :rights="rights"
            ></comment>
          </v-col>

          <v-col
            cols="12"
            md="5"
          >
            <!-- Results -->
            <results
              v-if="
                contract.state == 'approved' &&
                contract.isLatestApproved &&
                contract.tickets != null &&
                contract.tickets.other.length > 0 &&
                contract.performances.length > 0
              "
              :contract="contract"
            ></results>

            <!-- Log -->
            <log :contract="contract"></log>

            <!-- Contract download -->
            <!-- Download PDF -->
            <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase mr-2"
                @click="downloadPDF"
              >Download PDF<v-icon class="ml-1" right>mdi-file-move</v-icon>
            </v-btn> 
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase not-visible-on-print"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="exportContract"
                :columns="exportContractColumns"
                :filename="'dip-contract-export'"
                :sheetname="'Contract' + contract.id"
                >exporteer contract <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      color="success"
      >{{ snackbarText }}</v-snackbar
    >
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import router from '../../router/router';
import functions from '../../services/functions.service';

import DetailsContract from './components/DetailsContract.vue';
import Message from './components/details/Message.vue';
import Comment from './components/Comment.vue';
import Log from './components/details/Log.vue';
import Results from './components/details/Results.vue';

export default {
  name: 'ContractsDetail',
  props: ['contractId', 'type'],
  components: {
    Help,
    DetailsContract,
    Message,
    Comment,
    Log,
    Results,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'financiële afspraken',
          disabled: false,
          href: '/financiele-afspraken',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      contract: null,
      concept: null,
      snackbar: false,
      snackbarText: '',
      formError: '',
      formLoading: false,

      exportContractColumns: [
        { label: '', field: 'column1' },
        { label: '', field: 'column2' },
      ],
      exportContract: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('ContractsDetail');
    this.getContract();
  },
  methods: {
    getContract() {
      const vm = this;
      var apiLinkGet = '';

      if (this.type == 'huur') {
        apiLinkGet = '/RentContracts/Get';
      } else if (this.type == 'partage') {
        apiLinkGet = '/PartageContracts/Get';
      } else if (this.type == 'staffel') {
        apiLinkGet = '/TrancheContracts/Get';
      } else if (this.type == 'suppletie') {
        apiLinkGet = '/SuppletionContracts/Get';
      } else if (this.type == 'uitkoop') {
        apiLinkGet = '/BuyoutContracts/Get';
      }

      ApiService.get(apiLinkGet, {
        ContractId: this.contractId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const data = response.data.result;
            vm.contract = data;

            if (vm.contract.type.toLowerCase() != vm.type.toLowerCase()) {
              router.back();
            }

            var performancesString = '(';
            for (var i = 0; i < vm.contract.performances.length; i++) {
              if (i == 0) {
                performancesString += vm.contract.performances[i].id;
              } else {
                performancesString += ' - ' + vm.contract.performances[i].id;
              }
            }
            performancesString += ')';
            vm.breadcrumbs[1].text = vm.contract.production.title + ' ' + performancesString;

            // vm.contract.consumptionSurcharge = functions.getEuroCurrencyView(vm.contract.consumptionSurcharge)
            // vm.contract.serviceSurcharge = vm.contract.serviceSurcharge
            // vm.contract.wardrobeSurcharge = functions.getEuroCurrencyView(vm.contract.wardrobeSurcharge)
            // vm.contract.ticketPrice1 = functions.getEuroCurrencyView(vm.contract.ticketPrice1)
            // vm.contract.ticketPrice2 = functions.getEuroCurrencyView(vm.contract.ticketPrice2)
            // vm.contract.ticketPrice3 = functions.getEuroCurrencyView(vm.contract.ticketPrice3)
            // vm.contract.ticketPrice4 = functions.getEuroCurrencyView(vm.contract.ticketPrice4)
            // vm.contract.ticketPrice5 = functions.getEuroCurrencyView(vm.contract.ticketPrice5)
            // vm.contract.ticketPrice6 = functions.getEuroCurrencyView(vm.contract.ticketPrice6)
            // vm.contract.ticketPrice7 = functions.getEuroCurrencyView(vm.contract.ticketPrice7)
            // vm.contract.ticketPrice8 = functions.getEuroCurrencyView(vm.contract.ticketPrice8)
            // vm.contract.ticketPrice9 = functions.getEuroCurrencyView(vm.contract.ticketPrice9)
            // vm.contract.ticketPrice10 = functions.getEuroCurrencyView(vm.contract.ticketPrice10)

            if (vm.type == 'huur') {
              vm.contract.theaterFee = functions.getEuroCurrencyView(vm.contract.theaterFee);
              vm.contract.rent = functions.getEuroCurrencyView(vm.contract.rent);
              vm.contract.otherCosts = functions.getEuroCurrencyView(vm.contract.otherCosts);
            } else if (vm.type == 'partage') {
              vm.contract.providerGuarantee = functions.getEuroCurrencyView(vm.contract.providerGuarantee);
              vm.contract.consumerGuarantee = functions.getEuroCurrencyView(vm.contract.consumerGuarantee);
            } else if (vm.type == 'staffel') {
              vm.contract.theaterFee = functions.getEuroCurrencyView(vm.contract.theaterFee);
              vm.contract.providerGuarantee = functions.getEuroCurrencyView(vm.contract.providerGuarantee);
              vm.contract.consumerGuarantee = functions.getEuroCurrencyView(vm.contract.consumerGuarantee);
              vm.contract.partageProviderAmount1 = functions.getEuroCurrencyView(vm.contract.partageProviderAmount1);
              vm.contract.partageProviderAmount2 = functions.getEuroCurrencyView(vm.contract.partageProviderAmount2);
              vm.contract.partageProviderAmount3 = functions.getEuroCurrencyView(vm.contract.partageProviderAmount3);
              vm.contract.partageProviderAmount4 = functions.getEuroCurrencyView(vm.contract.partageProviderAmount4);
              vm.contract.combinedBudget = functions.getEuroCurrencyView(vm.contract.combinedBudget);
            } else if (vm.type == 'suppletie') {
              vm.contract.theaterFee = functions.getEuroCurrencyView(vm.contract.theaterFee);
              vm.contract.amount = functions.getEuroCurrencyView(vm.contract.amount);
              vm.contract.maximumAmount = functions.getEuroCurrencyView(vm.contract.maximumAmount);
            } else if (vm.type == 'uitkoop') {
              vm.contract.buyoutAmount = functions.getEuroCurrencyView(vm.contract.buyoutAmount);
            }

            this.createExportData();
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          router.back();
        });
    },
    downloadPDF() {
      var apiLinkGet = '';

      if (this.type == 'huur') {
        apiLinkGet = '/RentContracts/GetPdf';
      } else if (this.type == 'partage') {
        apiLinkGet = '/PartageContracts/GetPdf';
      } else if (this.type == 'staffel') {
        apiLinkGet = '/TrancheContracts/GetPdf';
      } else if (this.type == 'suppletie') {
        apiLinkGet = '/SuppletionContracts/GetPdf';
      } else if (this.type == 'uitkoop') {
        apiLinkGet = '/BuyoutContracts/GetPdf';
      }

      ApiService.getFile(apiLinkGet, {
        ContractId: this.contractId,
      })
      .then((response) => {
        var contentType = response.headers['content-type'];
        var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.download = 'contract-' + this.contract.production.title + '.pdf';
        fileLink.click();
        URL.revokeObjectURL(fileLink.href);
      })
      // eslint-disable-next-line
      .catch((error) => {
        console.log(error);
      });
    },
    createExportData() {
      const contract = this.contract;
      this.exportContract = [];

      // state
      var state =
        contract.state == 'proposed'
          ? 'voorgesteld'
          : contract.state == 'rejected'
          ? 'afgekeurd'
          : contract.state == 'approved'
          ? 'goedgekeurd'
          : contract.state == 'revised'
          ? 'aangepast voorstel'
          : contract.state;
      this.exportContract.push({ column1: 'status', column2: state });
      this.exportContract.push({ column1: 'laatste wijziging', column2: '' }); // date colum2 set by logs

      this.exportContract.push({});

      // general
      this.exportContract.push({ column1: 'kenmerk eigen systeem', column2: contract.code });
      this.exportContract.push({ column1: 'type afspraak', column2: contract.type });
      this.exportContract.push({ column1: 'aanbieder', column2: contract.producer.name });
      this.exportContract.push({ column1: 'productie', column2: contract.production.title });
      this.exportContract.push({ column1: 'afnemer', column2: contract.theater.name });

      let performancesString = '';
      contract.performances.forEach(function (performance) {
        performancesString +=
          performance.number +
          ' / ' +
          functions.getDateTimeView(performance.startDate) +
          ' (' +
          performance.area +
          '); ';
      });
      this.exportContract.push({ column1: 'uitvoeringen', column2: performancesString });

      this.exportContract.push({});

      // contact theater
      if (contract.theaterContact && contract.theaterContact.fullName != '') {
        this.exportContract.push({ column1: 'contactpersoon afnemer', column2: contract.theaterContact.fullName });
      }
      if (contract.theaterContact && contract.theaterContact.fullName != '') {
        this.exportContract.push({ column1: 'e-mailadres afnemer', column2: contract.theaterContact.email });
      } else {
        this.exportContract.push({ column1: 'e-mailadres afnemer', column2: contract.theaterContactEmail });
      }

      this.exportContract.push({});

      // contact provider
      if (contract.providerContact && contract.providerContact.fullName != '') {
        this.exportContract.push({ column1: 'contactpersoon aanbieder', column2: contract.providerContact.fullName });
      }
      if (contract.providerContact && contract.providerContact.email != '') {
        this.exportContract.push({ column1: 'e-mailadres aanbieder', column2: contract.providerContact.email });
      } else {
        this.exportContract.push({ column1: 'e-mailadres aanbieder', column2: contract.providerContactEmail });
      }

      this.exportContract.push({});

      if (this.type == 'huur') {
        this.exportContract.push({
          column1: 'theatertoeslag',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.theaterFee))),
        });
        this.exportContract.push({
          column1: 'huur (excl BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.rent))),
        });
        this.exportContract.push({
          column1: 'overige kosten',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.otherCosts))),
        });
      } else if (this.type == 'partage') {
        this.exportContract.push({
          column1: 'theatertoeslag',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.theaterFee))),
        });
        this.exportContract.push({
          column1: 'auteursrechten',
          column2: functions.getPercentageView(contract.copyrightPercentage),
        });

        if (contract.calculateCopyrightPercentage) {
          this.exportContract.push({
            column1: 'AR percentageberekening',
            column2:
              parseFloat(contract.copyrightPercentage) +
              '/1' +
              parseFloat(contract.copyrightPercentage).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }),
          });
        } else {
          this.exportContract.push({
            column1: 'AR percentageberekening',
            column2: parseFloat(contract.copyrightPercentage) + '/100',
          });
        }

        this.exportContract.push({
          column1: 'partage aanbieder',
          column2: functions.getPercentageView(contract.partageProviderPercentage),
        });
        this.exportContract.push({
          column1: 'garantie aan aanbieder (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.providerGuarantee))),
        });
        this.exportContract.push({
          column1: 'garantie aan afnemer (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.consumerGuarantee))),
        });
        this.exportContract.push({
          column1: 'garantieberekening',
          column2: contract.guaranteePerPerformance ? 'garantie per voorstelling' : 'garantie op totale recette',
        });
      } else if (this.type == 'staffel') {
        this.exportContract.push({
          column1: 'theatertoeslag',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.theaterFee))),
        });
        this.exportContract.push({
          column1: 'auteursrechten',
          column2: functions.getPercentageView(contract.copyrightPercentage),
        });

        if (contract.calculateCopyrightPercentage) {
          this.exportContract.push({
            column1: 'AR percentageberekening',
            column2:
              parseFloat(contract.copyrightPercentage) +
              '/1' +
              parseFloat(contract.copyrightPercentage).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }),
          });
        } else {
          this.exportContract.push({
            column1: 'AR percentageberekening',
            column2: parseFloat(contract.copyrightPercentage) + '/100',
          });
        }

        this.exportContract.push({
          column1: 'garantie aan aanbieder (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.providerGuarantee))),
        });
        this.exportContract.push({
          column1: 'garantie aan afnemer (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.consumerGuarantee))),
        });
        this.exportContract.push({
          column1: 'gezamenlijke pot (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.combinedBudget))),
        });

        this.exportContract.push({});

        this.exportContract.push({
          column1: 'partage aanbieder 1e tranche',
          column2:
            functions.getPercentageView(contract.partageProviderPercentage1) +
            ' - ' +
            functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.partageProviderAmount1))),
        });
        this.exportContract.push({
          column1: 'partage aanbieder 2e tranche',
          column2:
            functions.getPercentageView(contract.partageProviderPercentage2) +
            ' - ' +
            functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.partageProviderAmount2))),
        });
        this.exportContract.push({
          column1: 'partage aanbieder 3e tranche',
          column2:
            functions.getPercentageView(contract.partageProviderPercentage3) +
            ' - ' +
            functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.partageProviderAmount3))),
        });
        this.exportContract.push({
          column1: 'partage aanbieder 4e tranche',
          column2:
            functions.getPercentageView(contract.partageProviderPercentage4) +
            ' - ' +
            functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.partageProviderAmount4))),
        });
        this.exportContract.push({
          column1: 'partage aanbieder overig',
          column2: functions.getPercentageView(contract.partageProviderPercentageRest),
        });
      } else if (this.type == 'suppletie') {
        this.exportContract.push({
          column1: 'theatertoeslag',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.theaterFee))),
        });
        this.exportContract.push({
          column1: 'auteursrechten',
          column2: functions.getPercentageView(contract.copyrightPercentage),
        });

        if (contract.calculateCopyrightPercentage) {
          this.exportContract.push({
            column1: 'AR percentageberekening',
            column2:
              parseFloat(contract.copyrightPercentage) +
              '/1' +
              parseFloat(contract.copyrightPercentage).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }),
          });
        } else {
          this.exportContract.push({
            column1: 'AR percentageberekening',
            column2: parseFloat(contract.copyrightPercentage) + '/100',
          });
        }

        this.exportContract.push({
          column1: 'aanvulling tot (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.amount))),
        });
        this.exportContract.push({
          column1: 'maximale aanvulling (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.maximumAmount))),
        });
        this.exportContract.push({
          column1: 'partage aanbieder',
          column2: functions.getPercentageView(contract.partageProviderPercentage),
        });
        this.exportContract.push({
          column1: 'garantieberekening',
          column2: contract.guaranteePerPerformance ? 'afspraak per voorstelling' : 'afspraak over het totaal',
        });
      } else if (this.type == 'uitkoop') {
        this.exportContract.push({
          column1: 'auteursrechten',
          column2: functions.getPercentageView(contract.copyrightPercentage),
        });
        this.exportContract.push({
          column1: 'uitkoopsom (excl. BTW)',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.buyoutAmount))),
        });
      }

      this.exportContract.push({});

      // prices
      const ticketprices = [];
      for (var i = 1; i < 11; i++) {
        var priceCheck = contract['ticketPrice' + i];

        if (priceCheck != '0,00') {
          var price = parseFloat(functions.getEuroCurrencyDatabase(this.contract['ticketPrice' + i]));

          price = functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(price)));
          ticketprices.push({ id: i, price: price });
        }
      }

      let ticketpriceString = '';
      ticketprices.forEach(function (ticketprice) {
        ticketpriceString += ticketprice.price + '; ';
      });
      this.exportContract.push({ column1: 'afrekenprijzen (excl. alle toeslagen)', column2: ticketpriceString });

      // surcharges
      if (this.type == 'uitkoop') {
        this.exportContract.push({
          column1: 'theatertoeslag',
          column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.theaterFee))),
        });
      }
      this.exportContract.push({
        column1: 'consumptietoeslag',
        column2: functions.getEuroSignView(
          parseFloat(functions.getEuroCurrencyDatabase(contract.consumptionSurcharge))
        ),
      });
      this.exportContract.push({
        column1: 'servicetoeslag',
        column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.serviceSurcharge))),
      });
      this.exportContract.push({
        column1: 'garderobetoeslag',
        column2: functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(contract.wardrobeSurcharge))),
      });

      // inzet technici
      this.exportContract.push({ column1: 'aantal technici theater', column2: contract.theaterTechnicians });
      this.exportContract.push({ column1: 'aantal technici producent', column2: contract.producerTechnicians });

      // extra agreements
      this.exportContract.push({ column1: 'extra afspraken', column2: contract.remark });

      // default agreement
      if (contract.acceptTerms) {
        this.exportContract.push({
          column1: 'overeenkomst',
          column2: 'door aanbieder en afnemer overeengekomen standaardovereenkomst is van toepassing',
        });
      } else {
        this.exportContract.push({
          column1: 'overeenkomst',
          column2: 'door aanbieder en afnemer overeengekomen standaardovereenkomst is niet van toepassing',
        });
      }

      this.exportContract.push({});
      this.exportContract.push({});

      // logs
      this.exportContract.push({ column1: 'logs' });
      for (var j = contract.logs.length - 1; j >= 0; j--) {
        var stateLog =
          contract.logs[j].state == 'proposed'
            ? 'voorgesteld'
            : contract.logs[j].state == 'rejected'
            ? 'afgekeurd'
            : contract.logs[j].state == 'approved'
            ? 'goedgekeurd'
            : contract.logs[j].state == 'revised'
            ? 'herzien'
            : contract.logs[j].state;
        this.exportContract.push({
          column1: functions.getDateTimeView(contract.logs[j].modifiedDate),
          column2: stateLog + ' door ' + contract.logs[j].userFullName,
        });
        if (contract.logs[j].id == contract.id) {
          this.exportContract[1].column2 = functions.getDateTimeView(contract.logs[j].modifiedDate);
          j = -1;
        }
      }
    },
    submitContract() {
      this.formLoading = true;
      const contract = this.contract;

      var state;
      if (this.concept) {
        state = 'concept';
      } else if (contract.isApprovedBefore) {
        state = 'revised';
      } else {
        state = 'proposed';
      }

      const updateContract = {
        id: this.contractId,
        state: state,
        remark: contract.remark,
        acceptTerms: contract.acceptTerms,
        consumptionSurcharge: functions.getEuroCurrencyDatabase(contract.consumptionSurcharge),
        serviceSurcharge: functions.getEuroCurrencyDatabase(contract.serviceSurcharge),
        wardrobeSurcharge: functions.getEuroCurrencyDatabase(contract.wardrobeSurcharge),
        theaterTechnicians: contract.theaterTechnicians,
        producerTechnicians: contract.producerTechnicians,
        ticketPrice1: functions.getEuroCurrencyDatabase(contract.ticketPrice1),
        ticketPrice2: functions.getEuroCurrencyDatabase(contract.ticketPrice2),
        ticketPrice3: functions.getEuroCurrencyDatabase(contract.ticketPrice3),
        ticketPrice4: functions.getEuroCurrencyDatabase(contract.ticketPrice4),
        ticketPrice5: functions.getEuroCurrencyDatabase(contract.ticketPrice5),
        ticketPrice6: functions.getEuroCurrencyDatabase(contract.ticketPrice6),
        ticketPrice7: functions.getEuroCurrencyDatabase(contract.ticketPrice7),
        ticketPrice8: functions.getEuroCurrencyDatabase(contract.ticketPrice8),
        ticketPrice9: functions.getEuroCurrencyDatabase(contract.ticketPrice9),
        ticketPrice10: functions.getEuroCurrencyDatabase(contract.ticketPrice10),
        theaterContactId: (contract.theaterContact != null ? contract.theaterContact.id : 0),
        theaterContactEmail: (contract.theaterContact != null ? contract.theaterContact.email : contract.theaterContactEmail),
        agencyAsProvider: contract.agencyAsProvider,
        theaterFee: functions.getEuroCurrencyDatabase(contract.theaterFee)
      };

      var apiLinkPut = '';

      if (this.type == 'huur') {
        apiLinkPut = '/RentContracts/Update';

        updateContract.rent = functions.getEuroCurrencyDatabase(contract.rent);
        updateContract.otherCosts = functions.getEuroCurrencyDatabase(contract.otherCosts);
      } else if (this.type == 'partage') {
        apiLinkPut = '/PartageContracts/Update';

        updateContract.copyrightPercentage = functions.getDecimalDatabase(contract.copyrightPercentage);
        updateContract.calculateCopyrightPercentage = contract.calculateCopyrightPercentage;
        updateContract.providerGuarantee = functions.getEuroCurrencyDatabase(contract.providerGuarantee);
        updateContract.consumerGuarantee = functions.getEuroCurrencyDatabase(contract.consumerGuarantee);
        updateContract.partageProviderPercentage = functions.getDecimalDatabase(contract.partageProviderPercentage);
        updateContract.guaranteePerPerformance = contract.guaranteePerPerformance;
      } else if (this.type == 'staffel') {
        apiLinkPut = '/TrancheContracts/Update';

        updateContract.copyrightPercentage = functions.getDecimalDatabase(contract.copyrightPercentage);
        updateContract.calculateCopyrightPercentage = contract.calculateCopyrightPercentage;
        updateContract.providerGuarantee = functions.getEuroCurrencyDatabase(contract.providerGuarantee);
        updateContract.consumerGuarantee = functions.getEuroCurrencyDatabase(contract.consumerGuarantee);

        updateContract.partageProviderAmount1 = functions.getEuroCurrencyDatabase(contract.partageProviderAmount1);
        updateContract.partageProviderPercentage1 = functions.getDecimalDatabase(contract.partageProviderPercentage1);
        updateContract.partageProviderAmount2 = functions.getEuroCurrencyDatabase(contract.partageProviderAmount2);
        updateContract.partageProviderPercentage2 = functions.getDecimalDatabase(contract.partageProviderPercentage2);
        updateContract.partageProviderAmount3 = functions.getEuroCurrencyDatabase(contract.partageProviderAmount3);
        updateContract.partageProviderPercentage3 = functions.getDecimalDatabase(contract.partageProviderPercentage3);
        updateContract.partageProviderAmount4 = functions.getEuroCurrencyDatabase(contract.partageProviderAmount4);
        updateContract.partageProviderPercentage4 = functions.getDecimalDatabase(contract.partageProviderPercentage4);
        updateContract.partageProviderPercentageRest = functions.getDecimalDatabase(
          contract.partageProviderPercentageRest
        );
        updateContract.combinedBudget = functions.getEuroCurrencyDatabase(contract.combinedBudget);
      } else if (this.type == 'suppletie') {
        apiLinkPut = '/SuppletionContracts/Update';

        updateContract.copyrightPercentage = functions.getDecimalDatabase(contract.copyrightPercentage);
        updateContract.calculateCopyrightPercentage = contract.calculateCopyrightPercentage;
        updateContract.partageProviderPercentage = functions.getDecimalDatabase(contract.partageProviderPercentage);
        updateContract.amount = functions.getEuroCurrencyDatabase(contract.amount);
        updateContract.maximumAmount = functions.getEuroCurrencyDatabase(contract.maximumAmount);
        updateContract.guaranteePerPerformance = contract.guaranteePerPerformance;
      } else if (this.type == 'uitkoop') {
        apiLinkPut = '/BuyoutContracts/Update';

        updateContract.copyrightPercentage = functions.getDecimalDatabase(contract.copyrightPercentage);
        updateContract.buyoutAmount = functions.getEuroCurrencyDatabase(contract.buyoutAmount);
      }

      ApiService.put(apiLinkPut, updateContract)
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          if (response.data.success) {
            if (this.concept) {
              router.push({
                name: 'ContractsEdit',
                params: { type: this.type, contractId: response.data.result.contractId },
              });
            } else {
              if (state == 'proposed') {
                this.snackbarText = 'financiële afspraak voorgesteld';
              } else if (state == 'revised') {
                this.snackbarText = 'financiële afspraak herzien';
              }

              this.snackbar = true;
              setTimeout(function () {
                router.push({ name: 'Contracts' });
              }, 1000);
            }
          } else {
            this.formLoading = false;
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          }
        })
        // eslint-disable-next-line
        .catch((error) => {
          this.formLoading = false;
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
        });
    },
    submitProposed() {
      this.concept = false;
      this.submitContract();
    },
    submitConcept() {
      this.concept = true;
      this.submitContract();
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    contractId: function () {
      this.getContract();
    },
  },
};
</script>

<style>
@media print {
  #__userGuiding__preview_Root,
  .not-visible-on-print {
    display: none !important;
  }
}
</style>
